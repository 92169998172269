@import "./assets/styles/colors";
@import "./assets/styles/fonts";

$color-negative-rating: $t4-base-color !default;
$color-ok-rating: $t4-base-color !default;
$color-positive-rating: $t4-base-color !default;
$color-default-rating: $t4-base-color !default;

@import "../node_modules/css-star-rating/scss/star-rating";
@import "../node_modules/@angular/material/theming";

@include mat-core();

@keyframes slideLeft {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}

@keyframes slideRight {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}

@keyframes slideTop {
  from {
    top: -100vh;
  }
  to {
    top: 0;
  }
}

@keyframes slideBottom {
  from {
    bottom: -100vh;
  }
  to {
    bottom: 0;
  }
}

body {
  margin: 0;
  font-size: 16px;
}

.da-loading-screen {
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.transparent {
    background: rgba(255, 255, 255, 0.5);
    z-index: 11;
  }

  .da-loading-bar {
    height: 16px;
    background: #ccc;
    width: 50%;
    border-radius: 8px;
    position: relative;

    .da-loading-bar-value {
      background: #000;
      height: 16px;
      border-radius: 8px;
    }
  }
}

.da-frame {
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: $body-base-color;
}

.da-header {
  margin: 0;
  top: 0;
  left: 0;
  position: relative;
  min-width: 100vw;
  height: 110px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  background: $header-base-color;

  &.mobile {
    flex-direction: column;
    align-items: center;
  }
}

.da-content {
  padding: 85px 0 161px 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  max-width: 1366px;
  min-height: calc(100vh - 446px);
  text-align: center;

  .da-buttons-wrapper {
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    left: 0;
    bottom: 40px;
    pointer-events: none;

    .da-button-states {
      display: flex;
      column-gap: 45px;

      lib-state-icon {
        margin-top: -4px;
      }
    }

    .da-buttons-group {
      background: $body-base-color;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      padding: 20px;
      box-shadow: 0 3px 5px $shadow-base-color-dark;
      pointer-events: all;
    }
  }

  &.mobile {
    padding: 30px 2vw 200px 2vw;
    // width: 96vw;
    // max-width: 96vw;

    .da-buttons-wrapper {
      .da-buttons-group {
        width: auto;
        margin: 0 20px;

        &.withDashboard {
          width: 72vw;
        }
      }
    }
  }

  .da-tiles {
    margin: 42px 0 61px 0;
  }

  .da-tiles-wrapper {
    position: relative;
    animation-name: slideRight;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.1, 0.82, 0.76, 0.965);
  }

  .da-title {
    position: relative;
    animation-name: slideRight;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.1, 0.82, 0.76, 0.965);
  }

  .da-container {
    background: $header-base-color;
    border-radius: 25px;
    box-shadow: 0 3px 5px $shadow-base-color-container;
  }
}
// TODO: Schattierung wie diese selbst anlegen
.da-info-backdrop {
  background-color: transparent;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 998 !important;
  top: 0;
}

.modal {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  top: 0;
}

.modal-content {
  border: none !important;
}

.da-footer {
  margin: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  position: fixed;
  display: flex;
  justify-content: center;
  box-shadow: 0 -10px 30px $footer-shadow;
  width: 100vw;
  min-height: 90px;
  background: $header-base-color;
  animation-name: slideBottom;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.1, 0.82, 0.76, 0.965);
}

.da-white-stars {
  .rating.color-negative .star-container .star,
  .rating.color-ok .star-container .star,
  .rating.color-positive .star-container .star,
  .rating.color-default .star-container .star {
    svg {
      fill: #fff !important;
    }
  }
}

// version info

.version-info-button {
  position: absolute;
  font-size: 12px;
  width: 158px;
  top: 110px;
  left: 0;
}

.version-info {
  background: white;
  border: 1px solid #ccc;
  position: absolute;
  top: 130px;
  left: 0;
  padding: 7px;
  cursor: pointer;
  z-index: 99;

  &:hover {
    > div {
      max-width: 400px;
    }
  }

  > div {
    max-width: 100px;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    transition: max-width 0.5s ease-in-out;

    &.version-info--headline {
      font-weight: bold;

      &.version-info--headline_shortcut {
        padding-top: 20px;
      }
    }
  }
}

// for lib-input-date
.less-margin-bottom {
  margin-bottom: -33px;
}

.more-margin-top {
  margin-top: 25px;
}

//material styles

.mat-select-panel {
  background-color: #fff !important;
  border-radius: 24px !important;
  border: 0px solid transparent !important;
  margin-bottom: -10px !important;
  border-bottom: 1px solid $button-base-color !important;
  box-shadow: 0 3px 5px $shadow-base-color !important;
}

.mat-select-value {
  margin-left: 35px !important;
  color: $button-base-color !important;
}

.mat-form-field-infix {
  display: flex !important;
  border-top: 0px solid transparent !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-top: 0px solid transparent !important;
  color: $button-base-color !important;
  padding: 0.75em 0.75em 0 0 !important;
}

.mat-select-arrow-wrapper {
  margin-top: 17px !important;

  .mat-select-arrow {
    border-top-color: $button-base-color !important;
  }
}

.mat-option,
.mat-select {
  font-family: $font !important;
  color: $button-base-color !important;
}

.mat-select {
  padding-top: 4px !important;
}

.mat-option {
  text-align: center !important;
  border-radius: 24px !important;

  &:hover {
    background-color: $radiobutton-base-color !important;
  }
}

.mat-option-disabled {
  opacity: 0.5 !important;
}

.mat-form-field {
  margin-top: -8px !important;
  font-family: $font !important;
  //   width: 185px!important;
  text-align: center !important;
}

.mat-datepicker-content-container {
  background-color: #fff;
  border-radius: 24px;
  border: 0px solid transparent !important;
  border-bottom: 1px solid $button-base-color !important;
  box-shadow: 0 3px 5px $shadow-base-color !important;
}

.mat-icon-button,
.mat-button {
  fill: $button-base-color !important;
  color: $button-base-color !important;
  line-height: 22px !important;
  background-color: transparent;
  svg {
    fill: $button-base-color !important;
  }
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  display: none;
}

.mat-calendar-body-cell-content {
  border: 0px solid transparent !important;
  color: $button-base-color !important;

  &:hover {
    background-color: $button-base-color !important;
    color: $input-base-color !important;
  }
}

.mat-calendar-body-disabled {
  .mat-calendar-body-cell-content {
    color: $header-base-color !important;

    &:hover {
      background-color: transparent !important;
      color: $header-base-color !important;
    }
  }
}

// Cookie-Bot Styles
.CookieDeclaration {
  a, p {
    color: #fff;
  }
}

.CookieDeclarationDialogText,
.CookieDeclarationIntro,
.CookieDeclarationLastUpdated {
  margin: 0 0 14px 0;
}
.CookieDeclarationType {
  display: block;
  margin: 12px 0 12px 0;
  padding: 8px 8px 0 8px;
  border: 1px solid #333333;
  vertical-align: top;
}
.CookieDeclarationTypeHeader {
  font-weight: bold;
}
.CookieDeclarationTypeDescription {
  margin: 2px 0 16px 0;
}
.CookieDeclarationTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 18px 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.CookieDeclarationTableHeader {
  font-weight: bold;
  border-bottom: 1px solid #ffffff;
  text-align: left;
  padding: 4px;
  overflow: hidden;
}
.CookieDeclarationTableHeader[dir="rtl"] {
  text-align: right;
}
.CookieDeclarationTableCell {
  text-overflow: ellipsis;
  word-wrap: break-word;
  border-bottom: 1px solid #ffffff;
  vertical-align: top;
  padding: 4px 4px 5px 4px;
}
#CookieDeclarationUserStatusLabelConsentId {
  text-overflow: ellipsis;
  word-wrap: break-word;
}
@media all and (max-width: 600px) {
  .CookieDeclaration table.CookieDeclarationTable tr td:nth-child(n + 5),
  .CookieDeclaration table.CookieDeclarationTable tr th:nth-child(n + 5),
  .CookieDeclaration table.CookieDeclarationTable colgroup col:nth-child(n + 5) {
    display: none;
  }
}
