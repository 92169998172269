
:root {
    --button-base-color: 32, 56, 100; /* hintergrund button */
    --radiobutton-base-color: 221, 231, 238; /* hintergrund Auswahlfelder  */
    --input-base-color: 255, 255, 255;  /* hintergrund Eingabefelder  */
    --border-base-color: transparent;  /* randfarbe buttons, inputs etc und hintergrund für kleine Checkboxen */
    --icon-base-color: 32, 56, 100;  /*header icons  */
    --icon-white-color: 255, 255, 255;  /*white icons  */
    --warning-base-color: 253, 43, 81; /* error messages  */
    --progress-color: 255,255,255;  /* progress bar  */
    --body-base-color: 192, 209, 225; /*hintergrund body standard */
    --header-base-color: 221, 231, 238; /* hintergrund header standard */
    --shadow-base-color: 32, 56, 100, 0.5; /* schattenfarbe standard elemente */
    --shadow-base-color-container: 32, 56, 100; /* schattenfarbe layout  */
    --info-base-color: 212, 222, 231; /*info hintergrund standard  */
    --info-tabs-color: 192, 209, 225; /* info liste hintergrund */
    --button-secondary-color: 192, 209, 225; /* hintergrund button */
    --radiobutton-secondary-color: 255, 255, 255; /* hintergrund Auswahlfelder */
    --input-secondary-color: 255, 255, 255; /* hintergrund Eingabefelder */
    --border-secondary-color: 255,255,255; /* randfarbe buttons, inputs etc */
    --icon-secondary-color: 255,255,255; /* header icons */
    --warning-secondary-color: 253, 43, 81; /* error messages */
    --attention-color: 255, 231, 122; /* error messages  */
    --success-color: 2, 212, 174; /* erfolgreich übertragen */
    --error-background: 254, 202, 211; /* hintergrund error */
    --tab-background: 124, 143, 175; /* hintergrund error */
    --body-secondary-color: 32, 56, 100; /* hintergrund body overlay */
    --header-secondary-color: 100, 114, 146; /* hintergrund header overlay */
    --shadow-secondary-color: 255,255,255, 0.5; /* schattenfarbe overlay */
    --info-secondary-color: 255,255,255; /* info hintergrund overlay */
}


// grau: #C7C7C7 / rgb(199, 199, 199)
// grün: #02D4AE / rgb(2, 212, 174)
// rot: #FD2B51 / rgb(253, 43, 81)
// gelb: #FFE77A / rgb(255, 231, 122)
// pink: #FECAD3 / rgb(254, 202, 211)

// dunkelblau: #203864 / rgb(32, 56, 100)
// blaugrau: #7C8FAF / rgb(124, 143, 175)
// blau: #C0D1E1 / rgb(192, 209, 225)
// hellblau: #DDE7EE / rgb(221, 231, 238)

// buttons / inputs / auswahlfelder
$button-base-color: rgb(var(--button-base-color));
$button-shadow-base-color: rgb(var(--button-base-color), 0.5);
$radiobutton-base-color: rgb(var(--radiobutton-base-color));
$input-base-color: rgb(var(--input-base-color));
$border-base-color: rgb(var(--border-base-color));

$button-test-color: rgb(var(--button-base-color));

$button-secondary-color: rgb(var(--button-secondary-color));
$radiobutton-secondary-color: rgb(var(--radiobutton-secondary-color));
$input-secondary-color: rgb(var(--input-secondary-color));
$border-secondary-color: rgb(var(--border-secondary-color));

// slide colors
$body-base-color: rgb(var(--body-base-color));
$header-base-color: rgb(var(--header-base-color));
$shadow-base-color: rgb(var(--shadow-base-color), 0.5);
$shadow-base-color-dark: rgb(var(--shadow-base-color));
$shadow-base-color-container: rgb(var(--shadow-base-color-container), 0.3);
$footer-shadow: rgb(var(--shadow-base-color), 0.2);

$body-secondary-color: rgb(var(--body-secondary-color));
$header-secondary-color: rgb(var(--header-secondary-color));
$shadow-secondary-color: rgb(var(--shadow-secondary-color), 0.5);
$shadow-secondary-color-weak: rgb(var(--shadow-secondary-color), 0.3);

// gradients
$gradient-bg: linear-gradient(0deg, rgb(var(--body-base-color)) 0%, rgb(var(--header-base-color)) 100%); 
$gradient-progress: linear-gradient(90deg, rgb(var(--header-base-color)) 0%,rgb(var(--success-color)) 100%);
$gradient-accordion: linear-gradient(0deg, rgb(var(--header-base-color)) 0%, rgb(var(--body-base-color)) 100%); 
$gradient-progress-menu-left: linear-gradient(90deg, rgb(var(--header-base-color)) 0%, transparent 100%); 
$gradient-progress-menu-right: linear-gradient(90deg, transparent 0%, rgb(var(--header-base-color)) 100%);

// misc
$icon-base-color: rgb(var(--icon-base-color));
$icon-white-color: rgb(var(--icon-white-color));
$warning-base-color: rgb(var(--warning-base-color));
$attention-color: rgb(var(--attention-color));
$attention-color-weak: rgb(var(--attention-color), 0.3);
$info-base-color: rgb(var(--info-base-color));
$info-tabs-color: rgb(var(--info-tabs-color));
$tabs-color: rgb(var(--tab-background));

$icon-secondary-color: rgb(var(--icon-secondary-color));
$warning-secondary-color: rgb(var(--warning-secondary-color));
$success-color: rgb(var(--success-color)); //done
$info-secondary-color: rgb(var(--info-secondary-color));

// Transparencies
$body-base-color-90: rgba(var(--body-base-color),0.9);
$body-base-color-70: rgba(var(--body-base-color),0.7);
$info-base-color-20: rgba(var(--info-base-color),0.2);
$body-secondary-color-90: rgba(var(--body-secondary-color),0.9);
$body-secondary-color-70: rgba(var(--body-secondary-color),0.7);
$header-base-color-70: rgba(var(--header-base-color),0.7);
$header-base-color-50: rgba(var(--header-base-color),0.5);
$header-base-color-30: rgba(var(--header-base-color),0.3);



::ng-deep {
    .mat-progress-spinner circle, .mat-spinner circle {   
        stroke: $button-base-color;
    }
}